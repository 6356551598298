import { useProgress } from '@/hooks'
import React, { ReactNode } from 'react'
import { FaRedo } from 'react-icons/fa'

type Props = {
  load: () => void
}

const Reload = ({ load }: Props) => {
  const { inProgress } = useProgress()
  return (
    <div className="flex mb-2 mr-2 h-6">
      {inProgress && (
        <div className="text-sm text-gray-600 font-bold">Now loading...</div>
      )}
      <FaRedo
        className={`${
          inProgress ? 'text-gray-300' : 'text-gray-600 cursor-pointer'
        } ml-auto`}
        onClick={load}
      />
    </div>
  )
}

export default Reload

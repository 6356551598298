import React, { useRef, useState, useLayoutEffect } from 'react'
import ErrorMsg from './ErrorMsg'

type Props = {
  title: () => JSX.Element
  listData: any[]
  list: (data: any, index: number, total: number) => JSX.Element
  input: () => JSX.Element
  error?: string
}

const ListFrame = ({ title, list, input, listData, error }: Props) => {
  return (
    <>
      <div className="mt-4">
        <div className={`flex items-center mt-2`}>{title()}</div>
        {listData.length > 0 && (
          <div className="mb-2">
            {listData.map((data, index) => list(data, index, listData.length))}
          </div>
        )}
        {error != null && <ErrorMsg error={error} />}
        <div className="flex items-center mt-0">{input()}</div>
      </div>
    </>
  )
}

export default ListFrame

import { useProgress } from '@/hooks'
import useTranslation from '@/i18n'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'

interface Props {
  disAdd: boolean
  add: () => void
}

function BtnAdd({ disAdd, add }: Props) {
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  return (
    <div>
      <div className="flex items-center mt-0 w-full text-md">
        <FaPlusCircle
          className={`${
            disAdd || inProgress
              ? 'text-gray-400'
              : 'text-green-500 cursor-pointer'
          }  mr-2`}
          onClick={add}
        />
        <p
          className={`${
            disAdd || inProgress ? 'text-gray-400' : 'text-gray-800'
          }`}
        >
          {t('Add')}
        </p>
      </div>
    </div>
  )
}

export default BtnAdd
